// Back to Top Sticky Button
// usage: <a class="btn-back-to-top-sticky" href="#top" title="">Top</a>

(function () {
    var btnBackToTopSticky = document.querySelectorAll('a.btn-back-to-top-sticky')[0];

    if (btnBackToTopSticky) {

        function scrollFunction() {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                btnBackToTopSticky.style.display = "inline-block";
            } else {
                btnBackToTopSticky.style.display = "none";
            }
        }

        scrollFunction();

        window.onscroll = function() { 
            scrollFunction() 
        };
    }
    
})();