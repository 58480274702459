// UTMB Pagination Truncated
// See SF ContentPager/Pager.cshtml
// 2024/10/24 @mgcooper

// usage:
// <nav role="navigation" 
//      id="@String.Format("utmbsf-pagination-truncated_{0}", listGuid)" 
//      utmb-pager-list-id="@String.Format("utmbsf-pagination-list_{0}", listGuid)"
//      utmb-pager-current="@Model.CurrentPage"
//      utmb-pager-max="@Model.PagerNodes.Count()"
//      utmb-pager-template="@Model.RedirectUrlTemplate.ToString()"
//      class="utmbsf-pagination-truncated" 
//      aria-label="@Html.Resource("Pagination")">
// </nav>

(function () {

    function utmbPagination(pgrId) {

        if (!pgrId) return

        const pgr = document.getElementById(pgrId)

        if (!pgr) return

        const pgrListId = pgr.getAttribute("utmb-pager-list-id"),
            pgrCurrent = parseInt(pgr.getAttribute("utmb-pager-current")),
            pgrMax = parseInt(pgr.getAttribute("utmb-pager-max")),
            pgrLnkTemp = pgr.getAttribute("utmb-pager-template");

        if (!pgr && !pgrListId && !pgrCurrent && !pgrMax && !pgrLnkTemp) return

        function elementExistsInParent(element, parent) {
            if (parent != null) {
                if (element.parentElement) {
                    return element.parentElement === parent;
                }
            }
            return false;
        }

        let childElement = document.getElementById(pgrListId); // ul
        let parentElement = document.getElementById(pgrId); // nav

        if (parentElement && childElement) return

        function paginate({ current, max }) {
            if (!current || !max) return null

            let prev = current === 1 ? null : current - 1,
                next = current === max ? null : current + 1,
                items = [1]

            if (current === 1 && max === 1) return { current, prev, next, items }
            if (current > 4) items.push('…')

            let r = 2,
                r1 = current - r,
                r2 = current + r

            for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) items.push(i)

            if (r2 + 1 < max) items.push('…')
            if (r2 < max) items.push(max)

            return { current, prev, next, items }
        }

        function makeUL(array, current, max) {
            var list = document.createElement('ul');
            list.id = pgrListId;

            // prev
            if (current > 1) {
                let prevItem = document.createElement('li'),
                    prevSpan = document.createElement('span'),
                    prevLink = document.createElement('a'),
                    prevLinkText = document.createTextNode('«')

                prevSpan.setAttribute('aria-hidden', 'true');
                prevSpan.appendChild(prevLinkText);

                prevLink.appendChild(prevSpan);
                prevLink.title = "";
                prevLink.href = pgrLnkTemp.replace('{0}', (current - 1));
                prevLink.setAttribute('aria-label', 'Go to previous page');
                prevLink.setAttribute('class', 'prev');

                prevItem.appendChild(prevLink);
                list.appendChild(prevItem);
            }

            // items
            for (var i = 0; i < array.length; i++) {
                let item = document.createElement('li'),
                    span = document.createElement('span'),
                    link = document.createElement('a'),
                    linkText = document.createTextNode(array[i])

                if (array[i] != '…') {
                    link.appendChild(linkText);

                    if (array[i] == current) {
                        item.setAttribute('class', 'active');
                        link.setAttribute('aria-current', 'true');
                        link.setAttribute('aria-label', 'Current page ' + current);
                    } else {
                        link.setAttribute('aria-label', 'Go to page ' + array[i]);
                        link.href = pgrLnkTemp.replace('{0}', array[i]);
                    }

                    link.title = "";
                    link.appendChild(linkText);
                    item.appendChild(link);
                } else {
                    span.setAttribute('class', 'ellipses');
                    span.appendChild(linkText);
                    item.appendChild(span);
                }

                list.appendChild(item);
            }

            // next
            if (current < max) {
                let nextItem = document.createElement('li'),
                    nextSpan = document.createElement('span'),
                    nextLink = document.createElement('a'),
                    nextLinkText = document.createTextNode('»')

                nextSpan.setAttribute('aria-hidden', 'true');
                nextSpan.appendChild(nextLinkText);

                nextLink.appendChild(nextSpan);
                nextLink.title = "";
                nextLink.href = pgrLnkTemp.replace('{0}', (current + 1));
                nextLink.setAttribute('aria-label', 'Go to next page');
                nextLink.setAttribute('class', 'next');

                nextItem.appendChild(nextLink);
                list.appendChild(nextItem);
            }

            return list;
        }

        let pagination = paginate({ current: pgrCurrent, max: pgrMax });
        document.getElementById(pgrId).appendChild(makeUL(pagination.items, pgrCurrent, pgrMax));
    }

    const utmbPaginationNavs = document.querySelectorAll('.utmbsf-pagination-truncated');
    utmbPaginationNavs.forEach((element) => {
        utmbPagination(element.id);
    });

})();



// Pagination Algorithm -- for 'utmbsf-pagination-truncated' pagination
// SEE: -https://www.zacfukuda.com/blog/pagination-algorithm
// Output:
//     max: 1
//         c:1 [1]
//     max: 3
//         c:1 [1, 2, 3]
//         c:2 [1, 2, 3]
//         c:3 [1, 2, 3]
//     max: 5
//         c:1 [1, 2, 3, '…', 5]
//         c:2 [1, 2, 3, 4, 5]
//         c:3 [1, 2, 3, 4, 5]
//         c:4 [1, 2, 3, 4, 5]
//         c:5 [1, '…', 3, 4, 5]
//      max: 7
//         c:1 [1, 2, 3, '…', 7]
//         c:2 [1, 2, 3, 4, '…', 7]
//         c:3 [1, 2, 3, 4, 5, '…', 7]
//         c:4 [1, 2, 3, 4, 5, 6, 7]
//         c:5 [1, '…', 3, 4, 5, 6, 7]
//         c:6 [1, '…', 4, 5, 6, 7]
//         c:7 [1, '…', 5, 6, 7]
//     max: 9
//         c:1 [1, 2, 3, '…', 9]
//         c:2 [1, 2, 3, 4, '…', 9]
//         c:3 [1, 2, 3, 4, 5, '…', 9]
//         c:4 [1, 2, 3, 4, 5, 6, '…', 9]
//         c:5 [1, '…', 3, 4, 5, 6, 7, '…', 9]
//         c:6 [1, '…', 4, 5, 6, 7, 8, 9]
//         c:7 [1, '…', 5, 6, 7, 8, 9]
//         c:8 [1, '…', 6, 7, 8, 9]
//         c:9 [1, '…', 7, 8, 9]

//for (let max = 1; max < 10; max += 2) {
//    console.log(`max: ${max}`)
//    for (let current = 1; current <= max; current++) {
//        let pagination = paginate({ current, max })
//        console.log(`  c:${current}`, pagination.items)
//    }
//}
