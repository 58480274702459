// Search Handler

window.utmbWeb = window.utmbWeb || {};
(function (config) {

    if(config.useSearchMenu && (!config.pageIsDesignMode || config.pageIsPreviewMode)) {

        function submitSearch(searchQuery, selectedValue) {
            if (selectedValue == 'google') {
                window.location.href = 'https://www.google.com/search?site=utmb&client=utmb&proxystylesheet=utmb&output=&hl=en&q=' + 
                    searchQuery.replace(" ", "+") + '&SearchOptions=http://www.google.com/search';
            } else {
                window.location.href = 'https://www.utmb.edu/search?q=' + searchQuery.replace(" ", "+");
            }
        }

        function desktopSearch() {
            var searchQuery = document.getElementById('SearchQuery').value;
            var radios = document.getElementsByName("searchOptions");
            if (searchQuery && radios) {
                var selected = Array.from(radios).find(radio => radio.checked);
                submitSearch(searchQuery, selected.value); //alert(selected.value);
            }
        }

        function desktopSearchKeyMonitor(event) {
            if (event) {
                if (event.keyCode === 13) {
                    var searchQuery = document.getElementById('SearchQuery').value;
                    var radios = document.getElementsByName("searchOptions");
                    if (searchQuery && radios) {
                        var selected = Array.from(radios).find(radio => radio.checked);
                        submitSearch(searchQuery, selected.value); //alert(selected.value);
                    }
                }
            }
        }

        function mobiSearch() {
            var searchQuery = document.getElementById('MobiSearchQuery').value;
            var radios = document.getElementsByName("mobiSearchOptions");
            if (searchQuery && radios) {
                var selected = Array.from(radios).find(radio => radio.checked);
                submitSearch(searchQuery, selected.value); //alert(selected.value);
            }
        }

        function mobiSearchKeyMonitor(event) {
            if (event) {
                if (event.keyCode === 13) {
                    var searchQuery = document.getElementById('MobiSearchQuery').value;
                    var radios = document.getElementsByName("mobiSearchOptions");
                    if (searchQuery && radios) {
                        var selected = Array.from(radios).find(radio => radio.checked);
                        submitSearch(searchQuery, selected.value); //alert(selected.value);
                    }
                }
            }
        }

        window.addEventListener('keyup', desktopSearchKeyMonitor);
        window.addEventListener('keyup', mobiSearchKeyMonitor);

        document.getElementById('btnSearchQuery').addEventListener('click', desktopSearch);
        document.getElementById('btnMobiSearchQuery').addEventListener('click', mobiSearch);
    }

})(window.utmbWeb);