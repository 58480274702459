// Photo Figure w/ Caption
// Using viable replacement for jQuery document.ready
// -https://stackoverflow.com/questions/799981/document-ready-equivalent-without-jquery


(function () {

    //function ready(callback) {
    //    // in case the document is already rendered
    //    if (document.readyState != 'loading') callback();
    //    // modern browsers
    //    else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
    //    // IE <= 8
    //    else document.attachEvent('onreadystatechange', function () {
    //        if (document.readyState == 'complete') callback();
    //    });
    //}


    //ready(function () {
    //    // do something
    //    console.log("Photo Figure: Document is Ready");

    //    var photo = document.getElementsByClassName("photo");
    //    console.log("photo: " + photo.length)

    //    window.onload = function () {
    //        console.log("It's now fully loaded!")
    //        var photo2 = document.getElementsByClassName("photo");
    //        console.log("photo: " + photo2.length)
    //    }
    //});


    function insertAfter(referenceNode, newNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }


    function buildPhotoFigureElement(imgArray, imgFloat) {

        var i;
        for (i = 0; i < imgArray.length; i++) {
            //text += imgArray[i] + "<br>";

            var image = imgArray[i];
            var imageWidth = image.offsetWidth;
            var imageClassList = image.classList;

            var figure = document.createElement('figure');
            var figcaption = document.createElement('figcaption');
            var caption = document.createTextNode(image.getAttribute("title"));
            figcaption.appendChild(caption);

            insertAfter(image, figure); // Add to page

            switch (imgFloat) {
                case "left":
                    figure.classList.add("figure-photo-left");
                    break;
                case "right":
                    figure.classList.add("figure-photo-right");
                    break;
                case "none":
                default:
                    figure.classList.add("figure-photo");
            }

            
            figure.classList.add(imageClassList);
            figure.style.width = imageWidth + "px";

            figure.appendChild(image);
            figure.appendChild(figcaption);

            image.removeAttribute("class");
            

            

        }
    }





    // When Fully Loaded
    window.onload = function () {
        //console.log("It's now fully loaded!")

        // No Float
        var photoCaption = document.getElementsByClassName("photo-caption");
        //console.log("photoCaption: " + photoCaption.length);
        buildPhotoFigureElement(photoCaption, "none");

        // Left
        var photoCaptionLeft = document.getElementsByClassName("photo-left-caption");
        //console.log("photoCaptionLeft: " + photoCaptionLeft.length);
        buildPhotoFigureElement(photoCaptionLeft, "left");

        // Right
        var photoCaptionRight = document.getElementsByClassName("photo-right-caption");
        //console.log("photoCaptionRight: " + photoCaptionRight.length);
        buildPhotoFigureElement(photoCaptionRight, "right")
    }

})();
