// Splide Carousel Config
// *See Blog Slider

(function () {

    function setUtmbSplideRunner(utmbFeatureBoxCarouselId) {
        new Splide(utmbFeatureBoxCarouselId, {
            type: 'loop',
            perPage: 1,
            autoplay: true,
            pauseOnHover: true,
            //autoWidth: true,
            //focus    : 'center',
        }).mount();
    }

    function utmbSplideRunner() {
        var slides = document.getElementsByClassName('splide');
        for (var i = 0; i < slides.length; ++i) {
            var itemId = slides[i].id;  
            //item.innerHTML = 'this is value';
            //array1.find(element => element > 10);
            setUtmbSplideRunner('#' + itemId);
        }
    }

    function init(e) {
        utmbSplideRunner();
    }

    document.addEventListener("DOMContentLoaded", init, false);

})();



//// ORIGINAL IN-PAGE SPLIDE BLOG SLIDE CODE:
// (function () {

//     function setUtmbSplideRunner(utmbFeatureBoxCarouselId) {
//         new Splide(utmbFeatureBoxCarouselId, {
//             type: 'loop',
//             perPage: 1,
//             autoplay: true,
//             pauseOnHover: true,
//             //autoWidth: true,
//             //focus    : 'center',
//         }).mount();
//     }

//     function init(e) {

//         var blogslider = document.getElementById('utmbsf-blog-post-slider_@(blogSliderId)');
//         var blogsliderId = 'utmbsf-blog-post-slider_@(blogSliderId)__carousel';
//         setUtmbSplideRunner('#' + blogsliderId);
//     }

//     document.addEventListener("DOMContentLoaded", init, false);

// })();