// Navigate (site) Menu handler

(function () {
    let utmbBody = document.body;
    let config = window.utmbWeb || {};

    // Get Closest
    function getClosest(elem, selector) {
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;
    }

    // Parent Nodes
    function parentNodes(link) {
        // parent heirarchal structure
        let a = link;
        let els = [];
        while (a) {
            els.unshift(a);
            a = a.parentNode;
        };
        // filter heirachy
        var parents = [];
        for (var i = 0; i < els.length; i++) {
            let thisParent = getClosest(els[i], ".has-submenu");
            if (thisParent) {
                parents.push(thisParent.firstElementChild);
            }
        };
        return parents;
    }

    // Expand Active Heirarchy
    function expandActiveHeirarchy(arr, isActiveClick) {
        var i = 0;
        for (i = 0; i < arr.length; i++) {
            if (arr[i]) {
                arr[i].classList.add("active");
                if (isActiveClick || this.isExpandableActiveSubmenu) {
                    arr[i].setAttribute('aria-expanded', true);
                    if (arr[i].nextElementSibling) {
                        arr[i].nextElementSibling.classList.add("active");
                    }
                }
            }
        }
    }

    // Deactivate Other Nodes
    function deactivateOtherNodes(link, subs) {
        if (link && subs) {
            for (var i = 0; i < subs.length; i++) {
                if (subs[i].childNodes[1] !== link) {
                    subs[i].childNodes[1].classList.remove("active"); // !a.active
                    subs[i].childNodes[1].setAttribute('aria-expanded', false); // !a[aria-expanded="true"]
                    if (subs[i].childNodes[1].nextElementSibling) {
                        subs[i].childNodes[1].nextElementSibling.classList.remove("active"); // !submenu.active
                    }
                }
            };
        }
    }

    // SET DESKTOP: Reformat Navigate Menu UL for Modern Design Mode
    function setNavigateDesktop(navigate, menuNavigate) {
        if (navigate && menuNavigate) {
            var i, uls, subs, currents;

            // Navigate Menu
            navigate.setAttribute("data-accordion", true);
            navigate.childNodes[1].className = "";

            uls = navigate.querySelectorAll('.nav');
            for (i = 0; i < uls.length; i++) {
                uls[i].className = "";
                uls[i].classList.add("nav-menu");
            }

            subs = navigate.querySelectorAll('.has-submenu');
            for (i = 0; i < subs.length; i++) {
                var link = subs[i].childNodes[1];
                var caret = document.createElement("SPAN");
                caret.classList.add("caret");
                link.setAttribute("role", "button");
                link.appendChild(caret);
                link.setAttribute("aria-haspopup", true);
                link.setAttribute("aria-expanded", false);
                link.addEventListener("click", function (event) {
                    event.preventDefault();

                    var ariaExpanded = this.getAttribute('aria-expanded') === "true" ? true : false; // get aria state
                    var submenu = this.nextElementSibling; // submenu
                    var parents = parentNodes(this); // parent nodes

                    deactivateOtherNodes(this, subs); // deactivate all other refs (.active links)
                    expandActiveHeirarchy(parents, true); // activate parent heirarchy

                    // unclick this link if currently active
                    if (ariaExpanded) {
                        //console.log("aria-expanded YEP");
                        this.setAttribute('aria-expanded', false);
                        this.classList.remove("active");
                        if (submenu) {
                            submenu.classList.remove("active");
                        }
                    } else {
                        //console.log("aria-expanded NOPE");
                        this.classList.add("active");
                        this.setAttribute('aria-expanded', "true");
                        if (submenu) {
                            submenu.classList.add("active");
                        }
                    }
                });
            }

            currents = menuNavigate.querySelectorAll('.current');
            for (i = 0; i < currents.length; i++) {
                currents[i].className = "";
                currents[i].classList.add("active");
            }

            menuNavigate.classList.add("nav-site");
        }
    }

    // SET MOBILE: Clone Navigate Menu UL, Reformat and Append to Mobi Container
    function setNavigateMobile(menuNavigate) {
        if (menuNavigate) {
            var mobiMenu = document.getElementById("mobi-navigate");
            if (mobiMenu) {
                var mobiMenuNavigate = menuNavigate.cloneNode(true);
                if (mobiMenuNavigate) {
                    mobiMenuNavigate.id = "mobi-nav-site";
                    mobiMenuNavigate.className = "";
                    mobiMenuNavigate.classList.add("nav-site-mobi");
                    mobiMenuNavigate.classList.add("nav-menu");
                    mobiMenu.appendChild(mobiMenuNavigate);

                    var subs = mobiMenu.querySelectorAll('.has-submenu');
                    for (i = 0; i < subs.length; i++) {
                        var link = subs[i].childNodes[1];
                        link.addEventListener("click", function (event) {
                            event.preventDefault();

                            var ariaExpanded = this.getAttribute('aria-expanded') === "true" ? true : false; // get aria state
                            var submenu = this.nextElementSibling; // submenu
                            var parents = parentNodes(this); // parent nodes

                            deactivateOtherNodes(this, subs); // deactivate all other refs (.active links)
                            expandActiveHeirarchy(parents, true); // activate parent heirarchy

                            // unclick this link if currently active
                            if (ariaExpanded) {
                                //console.log("aria-expanded YEP");
                                this.setAttribute('aria-expanded', false);
                                this.classList.remove("active");
                                if (submenu) {
                                    submenu.classList.remove("active");
                                }
                            } else {
                                //---console.log("aria-expanded NOPE");
                                this.classList.add("active");
                                this.setAttribute('aria-expanded', "true");
                                if (submenu) {
                                    submenu.classList.add("active");
                                }
                            }
                        });
                    }


                }
            }
        }
    }

    // BODY: Set additional body classes
    function setCustomBodyClasses(config) {
        if (config && config.bodyCss) {
            document.getElementsByTagName("body")[0].classList.add(config.bodyCss);
        }
    }

    // Initialize...
    function init() {

        config.navigateMenuTheme = (utmbBody.hasAttribute("navigate-menu-theme")) ? utmbBody.getAttribute("navigate-menu-theme") : config.navigateMenuTheme;
        config.useNavigateMenu = (utmbBody.hasAttribute("use-navigate-menu")) ? Boolean(utmbBody.getAttribute("use-navigate-menu")) : 
            (config.navigateMenuTheme === "nav-remove") ? false : config.useNavigateMenu;

        setCustomBodyClasses(config);

        if (config.useNavigateMenu) {
            var navigate = document.getElementById("navigate");
            var menuNavigate = document.getElementById("nav-site");
            setNavigateDesktop(navigate, menuNavigate);
            setNavigateMobile(menuNavigate);
        }
    }
    init();

})();