// Open UK Modal via url hash(tag) "#"
// this grabs the modal id being passed in and opens the modal on arrival
// handy for external pages coming to this page for modal content
// see: -https://www.utmbhealth.com/mychart-info#modal-1d78e2d7-6914-6b9b-a0c5-ff0a0070e114
// #modal-1d78e2d7-6914-6b9b-a0c5-ff0a0070e114

(function () {
    var hashtag = window.location.hash;
    if (hashtag && hashtag.includes("#modal-")) {
        var modalId = hashtag.replace("#", "");
        var modal = document.getElementById(modalId);
        if (modal) {
            modal.setAttribute("class", "uk-modal uk-open");
            modal.setAttribute("style", "display: block");
        }
        else {
            console.log("no elementals")
        }
    }
})();