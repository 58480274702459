(function($) {

   // var $navLink = $menu.find('li.uk-parent > a');
    //var $navLinkActive = $menu.find('li.uk-parent > a'); //uk-active is-active

    // Caret
    function setCaret(link) {
        var caret = document.createElement('span');
        caret.className = 'caret';
        link.append(caret);
        //console.log("Setting Carets...");
    }

    // Do work...
    function init() {
        // var $navVertical = $("nav.nav-sidebar");
        // $.each($navVertical, function (i, val) {
        //     setMenu($(val));
        // });
        var $navParentLinks = $('nav.nav-vertical li.uk-parent > a');
        $.each($navParentLinks, function (i, val) {
            setCaret($(val));
        });
    }

    init();

})(jQuery);