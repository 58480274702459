// UIKit Nav Accordion - open parent of active nested items

(function () {
    var i, activeParents = document.getElementsByClassName("has-active-child");
    for (i = 0; i < activeParents.length; i++) {
        if (activeParents[i].getElementsByTagName('ul').length > 0) {
            activeParents[i].classList.add("uk-open");
            var nestedList = activeParents[i].getElementsByTagName('ul')[0];
            nestedList.setAttribute('aria-hidden', false);
            //---nestedList.setAttribute('aria-expanded', true);
        }
    }
})();