/* eslint-disable */
//
// UTMB Web Site Settings Configuration
//

window.utmbWeb = window.utmbWeb || {};
(function () {
    let utmbBody = document.body;
    let config = window.utmbWeb || {};

    function removePreloader() {
      utmbBody.classList.remove("utmb-page-preloader");
    }
  
    function designMode() {
      config.designMode = config.designMode ? config.designMode : "classic";
      utmbBody.classList.add("design-mode-" + config.designMode);
    }
  
    function theme() {
      if (config.theme) {
        utmbBody.classList.add(config.theme);
      }
    }

    function exploreMenu() {
      if (config.useExploreMenu && config.exploreMenuTheme && config.exploreMenuTheme !== "exp-remove") {
        if (config.designMode === "classic") {
          config.exploreMenuTheme = "exp-mega";
        } else {
          config.navigateMenuTheme = "exp-main";
        }
        utmbBody.classList.add(config.exploreMenuTheme);
      }
    }
  
    function navigateMenu() {
      if (config.useNavigateMenu && config.navigateMenuTheme && config.navigateMenuTheme !== "nav-remove") {
        if (config.designMode === "classic") {
          config.navigateMenuTheme = config.navigateMenuTheme;
        } else {
          config.navigateMenuTheme = "nav-main";
        }
        utmbBody.classList.add(config.navigateMenuTheme);
      }
    }

    function consoleLog() {
      if (config.useConsoleLog) {
        console.log(config);
      }
    }
    
    function utmbWebConfig() {
        // Body Attributes Ready?
        var useBodyAttributes = (document.body) ? true : false;

        // UTMB Web Head Config
        function override(val, att) {
            var res = val;
            if (useBodyAttributes) {
                var utmbBody = document.body;
                res = (utmbBody.hasAttribute(att)) ? utmbBody.getAttribute(att) : val;
            }
            if (res == "null") res = null;
            return res;
        }

        // frontend core UTMB Web *required (alpha)
        utmbWeb.designMode = override(utmbWeb.designMode, "design-mode");
        utmbWeb.exploreMenuTheme = override(utmbWeb.exploreMenuTheme, "explore-menu-theme");
        utmbWeb.mobileMenuTheme = override(utmbWeb.mobileMenuTheme, "mobile-menu-theme");
        utmbWeb.navigateMenuTheme = override(utmbWeb.navigateMenuTheme, "navigate-menu-theme");
        utmbWeb.searchMenuTheme = override(utmbWeb.searchMenuTheme, "search-menu-theme");
        utmbWeb.theme = override(utmbWeb.theme, "theme");
        utmbWeb.useConsoleLog = override(utmbWeb.useConsoleLog, "use-console-log", "bool");
        utmbWeb.useExploreMenu = (utmbWeb.navigateMenuTheme === "exp-remove") ? false : Boolean(override(utmbWeb.useExploreMenu, "use-explore-menu"));
        utmbWeb.useMobileMenu = (utmbWeb.navigateMenuTheme === "mobi-remove") ? false : Boolean(override(utmbWeb.useMobileMenu, "use-mobile-menu"));
        utmbWeb.useNavigateMenu = (utmbWeb.navigateMenuTheme === "nav-remove") ? false : Boolean(override(utmbWeb.useNavigateMenu, "navigate-menu-theme"));
        utmbWeb.useSearchMenu = (utmbWeb.navigateMenuTheme === "search-remove") ? false : Boolean(override(utmbWeb.useSearchMenu, "use-search-menu"));
        utmbWeb.useUtmbAlertSystem = Boolean(override(utmbWeb.useUtmbAlertSystem, "use-utmb-alert-system"));
        utmbWeb.utmbWebVersion = override(utmbWeb.utmbWebVersion, "utmb-web-version");

        // other settings *optional (alpha) - include these if their respective body attributes are present
        utmbWeb.exploreMenuSource = override(utmbWeb.exploreMenuSource, "explore-menu-source");
        utmbWeb.headerSubtitle = override(utmbWeb.headerSubtitle, "header-subtitle");
        utmbWeb.headerTitle = override(utmbWeb.headerTitle, "header-title");
        utmbWeb.isDevMode = Boolean(override(utmbWeb.isDevMode, "is-dev-mode")); // is on localhost
        utmbWeb.isInternal = Boolean(override(utmbWeb.isInternal, "is-internal")); // in UTMB's local IP Range'
        utmbWeb.isIntranet = Boolean(override(utmbWeb.isIntranet, "is-intranet")); // is on intranet.utmb.edu
        utmbWeb.isSitefinity = Boolean(override(utmbWeb.isSitefinity, "is-sitefinity"));
        utmbWeb.kudos1 = override(utmbWeb.kudos1, "kudos-1");
        utmbWeb.kudos1Url = override(utmbWeb.kudos1Url, "kudos-1-url");
        utmbWeb.kudos2 = override(utmbWeb.kudos2, "kudos-2");
        utmbWeb.kudos2Url = override(utmbWeb.kudos2Url, "kudos-2-url");
        utmbWeb.kudos3 = override(utmbWeb.kudos3, "kudos-3");
        utmbWeb.kudos3Url = override(utmbWeb.kudos3Url, "kudos-3-url");
        utmbWeb.pageId = override(utmbWeb.pageId, "page-id");
        utmbWeb.pageIsDesignMode = Boolean(override(utmbWeb.pageIsDesignMode, "page-is-design-mode"));
        utmbWeb.pageIsPreviewMode = Boolean(override(utmbWeb.pageIsPreviewMode, "page-is-preview-mode"));
        utmbWeb.pageTemplateId = override(utmbWeb.pageTemplateId, "page-template-id");
        utmbWeb.pageTemplateTitle = override(utmbWeb.pageTemplateTitle, "page-template-title");
        utmbWeb.pageTitle = override(utmbWeb.pageTitle, "page-title");
        utmbWeb.pageNodeKey = override(utmbWeb.pageNodeKey, "page-node-key");
        utmbWeb.pageUrl = override(utmbWeb.pageUrl, "page-url");
        utmbWeb.pageUrlName = override(utmbWeb.pageUrlName, "page-url-name");
        utmbWeb.pageWidth = override(utmbWeb.pageWidth, "page-width");
        utmbWeb.siteCss = override(utmbWeb.siteCss, "site-css");
        utmbWeb.siteId = override(utmbWeb.siteId, "site-id");
        utmbWeb.siteJs = override(utmbWeb.siteJs, "site-js");
        utmbWeb.siteRoot = override(utmbWeb.siteRoot, "site-root");
        utmbWeb.siteRoot = override(utmbWeb.siteRoot, "site-title");
        utmbWeb.useArtboard = Boolean(override(utmbWeb.useArtboard, "use-artboard"));
        utmbWeb.useNavigateMenuSitefinityApi = Boolean(override(utmbWeb.useNavigateMenuSitefinityApi, "use-navigate-menu-sitefinity-api"));
        utmbWeb.navigateMenuLevels = parseInt(override(utmbWeb.navigateMenuLevels, "navigate-menu-levels"));
        utmbWeb.navigateMenuUseAccordion = Boolean(override(utmbWeb.navigateMenuUseAccordion, "navigate-menu-use-accordion"));
        utmbWeb.rootDomainTitle = override(utmbWeb.rootDomainTitle, "root-domain-title");
        utmbWeb.rootDomainUrl = override(utmbWeb.rootDomainTitle, "root-domain-url");
        utmbWeb.searchSiteUrl = override(utmbWeb.searchSiteUrl, "search-site-url");
        utmbWeb.useBreadcrumbs = Boolean(override(utmbWeb.useBreadcrumbs, "use-breadcrumbs"));
        utmbWeb.useFooter = Boolean(override(utmbWeb.useFooter, "use-footer"));
        utmbWeb.useFooterSitefinityLogin = Boolean(override(utmbWeb.useFooterSitefinityLogin, "use-footer-sitefinity-login"));
        utmbWeb.usePadding = Boolean(override(utmbWeb.usePadding, "use-padding"));
        utmbWeb.usePagePreloader = Boolean(override(utmbWeb.usePagePreloader, "use-page-preloader"));
        utmbWeb.useProdLoginUrls = Boolean(override(utmbWeb.useProdLoginUrls, "use-prod-login-urls"));
        utmbWeb.useRegion_atopContent = Boolean(override(utmbWeb.useRegion_atopContent, "use-region-atop-content"));
        utmbWeb.useRegion_articleContent = Boolean(override(utmbWeb.useRegion_articleContent, "use-region-article-content"));
        utmbWeb.useRegion_baseContent = Boolean(override(utmbWeb.useRegion_baseContent, "use-region-base-content"));
        utmbWeb.useRegion_featureContent = Boolean(override(utmbWeb.useRegion_featureContent, "use-region-feature-content"));
        utmbWeb.useRegion_topbarContent = Boolean(override(utmbWeb.useRegion_topbarContent, "use-region-topbar-content"));
        utmbWeb.useRegion_subContent = Boolean(override(utmbWeb.useRegion_subContent, "use-region-sub-content"));
        utmbWeb.useUtmbAnalytics = Boolean(override(utmbWeb.useUtmbAnalytics, "use-utmb-analytics"));
        utmbWeb.useVendorBootstrap = Boolean(override(utmbWeb.useVendorBootstrap, "use-vendor-bootstrap"));
        utmbWeb.useVendorIconsFontAwesome = Boolean(override(utmbWeb.useVendorIconsFontAwesome, "use-vendor-icons-font-awesome"));
        utmbWeb.useVendorIconsFoundation = Boolean(override(utmbWeb.useVendorIconsFoundation, "use-vendor-icons-foundation"));
        utmbWeb.useVendorJQuery = Boolean(override(utmbWeb.useVendorJQuery, "use-vendor-jquery"));
        utmbWeb.useVendorVueJs = Boolean(override(utmbWeb.useVendorVueJs, "use-vendor-vuejs"));
        utmbWeb.utmbAlertSystemUri = override(utmbWeb.utmbAlertSystemUri, "utmb-alert-system-uri");
        utmbWeb.utmbWebAssetPath = override(utmbWeb.utmbWebAssetPath, "utmb-web-asset-path");
        utmbWeb.utmbWeb = override(utmbWeb.utmbWeb, "utmb-web");

        // *NEW*
        // utmbWeb.headerAttr = override("header-attr");
        // utmbWeb.headerCss = override("header-css");
        // utmbWeb.bodyCss = override("body-css");
        
        window.utmbWeb = utmbWeb;
    }



    function setBodyClasses() {
        removePreloader();
        designMode();
        theme();
        exploreMenu();
        navigateMenu();
        consoleLog();
      }

    function init() {
        setBodyClasses();
    }
  
    window.onload = utmbWebConfig();
    init();

})();

